import { createStyles, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useList } from "../../apollo/networking";
import { Currency } from "../../models/currency";
import { InvoiceRefundFragment, InvoiceRefundModel } from "../../models/invoice-refund.model";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";

type Props = {
    invoice_id: number;
    currency: Currency;
}


export const InvoiceRefunds = ({
    invoice_id,
    currency,
}: Props) => {
    const classes = useStyles({});
    const {
        items,
        loading,
        refetch,
    } = useList<InvoiceRefundModel>({
        entity_name: 'invoice_refund',
        fragment: InvoiceRefundFragment,
        query_inner: `where: {
            invoice_id: {_eq: $invoice_id}
        }, order_by: {id: desc}`,
        clause_outer: `$invoice_id: bigint!`,
        variables: {
            invoice_id,
        }
    });

    return <>
        <div className={classes.header}>
            <Typography variant='overline'>Issued Refunds</Typography>
        </div>
        {items.map(item => <div
            key={item.id} className={classes.item}>
            <div style={{ flex: 1 }}>
                Issued {moment(item.created_at).format('LLL')}
            </div>
            <Typography variant='body1'
                color='secondary'>-{getFormattedCurrency(
                    item.amount,
                    currency,
                )}</Typography>
        </div>)}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}))