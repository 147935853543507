import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useList } from "../../apollo/networking";
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { AppLayout } from "../../layouts/app-layout";
import { AttendeeDetailFragment, AttendeeDetailModel } from "../../models/attendee-detail.model";
import { AttendeeFragment, AttendeeModel } from "../../models/attendee.model";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { AttendeeEditorDialog } from "../attendee-editor/attendee-editor.dialog";
import { TicketItem } from "../ticket-list/ticket-item";


type Props = {
    ticket_id: number;
}

export const TicketPage = ({
    ticket_id
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const {
        entity,
        loading,
    } = useGetByPk<AttendeeDetailModel>({
        entity_name: 'attendee',
        fragment: AttendeeDetailFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: ticket_id,
        }],
    })
    const [edit_at, setEditAt] = React.useState<AttendeeModel | undefined>();

    return <AppLayout>
        <PageHeader
            title='Ticket'
            back_url='/tickets'
        />
        {edit_at && entity ? <AttendeeEditorDialog
            item={entity}
            onClose={() => setEditAt(undefined)}
            onFinish={() => setEditAt(undefined)}
        /> : null}
        {entity ? <TicketItem
            key={entity.id}
            onEdit={() => setEditAt(entity)}
            item={entity}
        /> : null}
        {loading ? <LoadingDots /> : null}
    </AppLayout>
}

const useStyles = makeStyles(theme => createStyles({

}))