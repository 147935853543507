import { Field, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";


interface Props extends FormikProps<any> {
}

export const ReferrerForm = ({
}: Props) => {
    return <>
        <Field
            name='name'
            component={TextField}
            label='Full name'
            margin='normal'
            fullWidth
        />
        <Field
            name='phone'
            type='tel'
            component={TextField}
            label='Phone'
            margin='normal'
            fullWidth
        />
        <Field
            name='code'
            component={TextField}
            label='Referral Code (e.g. drsaj)'
            margin='normal'
            helperText='Enter a recognizable code e.g. jen-99 or tooth-doctor. Only letters (A-Z), numbers (0-9) and hyphen (-) characters allowed'
            fullWidth
        />
    </>
}
