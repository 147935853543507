import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import Linkify from 'react-linkify';
import { textWithLineBreaks } from '../utils/text-with-line-breaks';


type Props = {
	max_chars?: number;
	text: string;
	component?: any;
	read_more?: string | false;
	onExpand?: (expand?: boolean) => void;
}


export const LinkifyTruncateText = ({
	max_chars = 200,
	text,
	component,
	read_more = 'Read more',
	onExpand,
}: Props) => {
	const classes = useStyles({});
	const [limit, setLimit] = React.useState(true);

	const onToggle = (e: React.MouseEvent<any>) => {
		if (!!read_more) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (onExpand) {
			return onExpand(!limit);
		}
		setLimit(!limit);
	}

	const [trunc_text, is_truncated] = React.useMemo(() => {
		if (limit && text.length > max_chars) {
			const _text = text.replace('/n', '')
				.substr(0, max_chars);
			return [`${_text}...`, true]
		}
		return [text, false];
	}, [limit, max_chars, text])

	return <Linkify>
		{textWithLineBreaks(trunc_text)} <a 
		onClick={onToggle}
		className={classes.readMore}>{is_truncated ? 'Read more' : '^ Read less'}</a>
	</Linkify>
}

const useStyles = makeStyles((theme) => createStyles({
	readMore: {
		cursor: 'pointer',
		fontWeight: 'bold',
		'&:hover': {
			textDecoration: 'underline',
		}
	}
}));