import { DialogContent } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../app-dialog";
import { AttendeeFragment, AttendeeModel } from "../../models/attendee.model";
import { BasicDialogActions } from "../../ui/dialog";
import { LoadingButton } from "../../ui/loading-button";
import { AttendeeInitialValues, AttendeeValidationSchema } from "./attendee.validation";


type Props = {
    item: AttendeeModel;
    onFinish: (product: AttendeeModel) => void;
}

export const AttendeeEdit = ({
    onFinish,
    item,
}: Props) => {
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'attendee',
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: item.id,
        }],
        fragment: AttendeeFragment,
    });

    const app_dialog = useAppDialog();

    const onSubmit = async (values: any) => {
        try {
            const result = await onUpdate({
                ...values,
            }) as AttendeeModel;
            if (!!result) {
                app_dialog.showSnackbar('Ticket saved');
                onFinish(result);
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={AttendeeInitialValues(item)}
        validationSchema={AttendeeValidationSchema}
        onSubmit={onSubmit}>{() => <Form noValidate>
            <DialogContent>
                <Field
                    name='name'
                    component={TextField}
                    label='Name'
                    margin='normal'
                    fullWidth
                />
                <Field
                    name='email'
                    type='email'
                    component={TextField}
                    label='Email'
                    margin='normal'
                    fullWidth
                />
                <Field
                    name='agd_id'
                    component={TextField}
                    label='AGD ID'
                    margin='normal'
                    fullWidth
                />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton loading={loading}
                    color='primary'
                    size='large'
                    type='submit'
                    variant='contained'>
                    Save
                </LoadingButton>
            </BasicDialogActions>

        </Form>}
    </Formik>
}
