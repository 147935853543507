import { createStyles, DialogContent, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useList } from "../../apollo/networking";
import { AttendeeCertificateFragment, AttendeeCertificateModel } from "../../models/attendee-certificate.model";
import { AttendeeModel } from "../../models/attendee.model";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { AttendeeEditorDialog } from "../attendee-editor/attendee-editor.dialog";
import { CertificateItem } from "../certificate-list/certificate-item";


type Props = {
    onClose: () => void;
    event_id: number;
}

export const EventCertificatesDialog = ({
    event_id,
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [edit_attendee, setEditAttendee] = React.useState<AttendeeModel|undefined>();
    const {
        items,
        loading,
    } = useList<AttendeeCertificateModel>({
        fragment: AttendeeCertificateFragment,
        entity_name: 'attendee_certificate',
        query_inner: `where: {
            certificate: {
                event_id: {_eq: $event_id}
            }
        }`,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    })
    return <BasicDialog
        id='event-certificates-dialog'
        onClose={onClose}
        open={true}>
        {edit_attendee ? <AttendeeEditorDialog 
            item={edit_attendee}
            onClose={() => setEditAttendee(undefined)}
            onFinish={() => setEditAttendee(undefined)}
        /> : null}
        <BasicDialogTitle title='Certificates' onClose={onClose} />
        <DialogContent>
            <Grid container spacing={2}>
                {items.map(item => <CertificateItem
                    key={item.id}
                    onEdit={() => setEditAttendee(item.attendee)}
                    item={item}
                />)}
            </Grid>
            {!loading && items.length === 0 ? <EmptyListComponent
                message='No certificates found.'
            /> : null}
            {loading ? <LoadingDots /> : null}
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({

}))