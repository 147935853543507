import { createStyles, Link, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";


type Props = {
    event_resources: {
        id: number;
        name: string;
        created_at: string;
        link: string;
    }[]
}

export const EventResources = ({
    event_resources,
}: Props) => {
    const classes = useStyles({});

    if (event_resources.length === 0) {
        return null
    }
    
    return <div className={classes.section}>
        <Typography variant='overline'>Resources/Notes</Typography>
        {(event_resources || []).map(item => <div
            key={item.id} className={classes.notification}>
            <Link target='_blank' href={item.link}>{item.name}</Link>
            <Typography variant='body2' color='textSecondary'>{moment(item.created_at).fromNow()}</Typography>
        </div>)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    section: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    notification: {
        padding: theme.spacing(1, 0),
    },
}))