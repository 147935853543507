import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useUser } from '../apollo/use-user';
import { EdContainer } from '../ui/ed-container';
import { AppFooter } from './app-footer';
import { AppHeader } from './app-header';

type Props = {
	no_container?: boolean;
	max_width?: 'sm' | 'md' | 'lg' | 'xl';
}

export const AppLayout: React.FC<Props> = ({
	no_container,
	max_width = 'md',
	children
}) => {
	const {
		email,
	} = useUser();
	return <>
		<AppHeader />
		{no_container ? <>
			{children}
		</> : <EdContainer max_width={max_width}>
			<>
				<Box pb={4}>
					<Typography
						variant='body1'>Logged in as <strong>{email}</strong></Typography>
				</Box>
				{children}
			</>
		</EdContainer>}
		<AppFooter />
	</>
}
