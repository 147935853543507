import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useKeysetPagination } from "../apollo/networking/use-keyset-pagination";
import { WebinarAttendeeModel } from "../models/webinar-attendee.model";
import { WebinarPostAttendeeFragment, WebinarPostAttendeeModel } from "../models/webinar-post.model";
import { LoadingButton } from "../ui/loading-button";
import { usePostQuestion } from "./use-post-question";
import { WebinarQuestionInput } from "./webinar-question-input";


type Props = {
    attendance_id: number;
    attendees: WebinarAttendeeModel[];
}

export const WebinarQuestions = ({
    attendance_id,
    attendees,
}: Props) => {
    const classes = useStyles({});
    const {
        posting,
        postQuestion,
    } = usePostQuestion(attendance_id);
    const {
        items,
        loading,
        has_more,
        fetchMore,
        refetch,
    } = useKeysetPagination<WebinarPostAttendeeModel>({
        entity_name: 'webinar_post_attendee',
        fragment: WebinarPostAttendeeFragment,
        query_inner: `where: {
            _and: [
                {attendance_id: {_eq: $attendance_id}},
                {id: {_lt: $cursor}},
            ],
        }, order_by: {id: desc}`,
        clause_outer: `$attendance_id: bigint!, $cursor: bigint!`,
        limit: 20,
        variables: {
            attendance_id,
            cursor: Number.MAX_SAFE_INTEGER
        },
    });

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            attendance_id,
            cursor: items[items.length - 1].id,
        })
    }
    return <Box pt={2}>
        <Typography variant='h6'>
            Questions & Comments <span role='img'>👇</span>
        </Typography>
        <WebinarQuestionInput
            attendees={attendees}
            posting={posting}
            onPost={postQuestion}
            refetch={() => {
                refetch({
                    attendance_id,
                    cursor: Number.MAX_SAFE_INTEGER
                })
            }}
        />
        {items.map(item => <div
            key={item.id}
            className={classes.message}>
            <Typography variant='body2'><strong>{item.name}</strong> &middot; {moment(item.created_at).subtract(20, 's').fromNow()}</Typography>
            <Typography variant='body1'>{item.message}</Typography>
        </div>)}
        <Box py={2}>
            <LoadingButton
                loading={loading}
                fullWidth
                disabled={!has_more}
                onClick={loadMore}>{has_more ? 'Load more posts' : 'End of posts'}</LoadingButton>
        </Box>
    </Box>
}

const useStyles = makeStyles(theme => createStyles({
    message: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 0),
    },
}))