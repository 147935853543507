export const PROD_CONFIG = {
  production: true,
  ws_url: 'wss://certifysimple-graphql.herokuapp.com/v1/graphql',
  http_url: 'https://certifysimple-graphql.herokuapp.com/v1/graphql',
  default_page_size: 30,
  image_base_url: 'https://d1niz8ad8nu5h5.cloudfront.net',
  image_bucket: 'edropin-images-1',
  frontend_url: 'https://certifysimple.com',
  checkout_url: 'http://checkout.certifysimple.com',
  stripe_key: 'pk_live_44pt8QWg7JHqAQGmUWM28sDX',
  firebaseConfig: {
    apiKey: "AIzaSyDwx4klV719KaxtNW5-_kWBrdLiF_K3tus",
    authDomain: "certify-simple.firebaseapp.com",
    projectId: "certify-simple",
    storageBucket: "certify-simple.appspot.com",
    messagingSenderId: "1016111139978",
    appId: "1:1016111139978:web:d0995ecf71c7e63be33173",
    measurementId: "G-ZHX91R4W3C"
  },
  recaptcha_key: '6LfL2LYaAAAAAJpt0QfThjOxI72U6avK91cXuoDs',
};
