import { AttendeeFragment, AttendeeModel } from "./attendee.model";

export type AttendeeDiplomaModel = {
    id: number;
    secret: string;
    updated_at: string;
    attendee: AttendeeModel;
    diploma: {
        id: number;
        title: string;
        event: {
            id: number;
            title: string;
        }
    }
};


export const AttendeeDiplomaFragment = `
    id
    secret
    updated_at
    attendee {
        ${AttendeeFragment}
    }
    diploma {
        id
        title
        event {
            id
            title
        }
    }
`;
