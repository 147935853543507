import { Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { WebinarAttendeeModel } from "../models/webinar-attendee.model";
import { AttendanceState, AttendanceStateOptions, getAttendanceStateOptionLabel } from "../models/webinar.model";
import { LoadingButton } from "../ui/loading-button";


type Props = {
    item: WebinarAttendeeModel;
    is_loading?: boolean;
    state: AttendanceState;
    onCheckin: () => void;
}

export const WebinarTicketItem = ({
    item,
    state,
    is_loading,
    onCheckin,
}: Props) => {
    const classes = useStyles({});

    const [is_checked_in, is_checked_out] = React.useMemo(
        () => [
            item.dropins && item.dropins.length > 0 && !!item.dropins[0].checkin_at,
            item.dropins && item.dropins.length > 0 && !!item.dropins[0].checkout_at,
        ],
        [item.dropins])

    return <div className={classes.ticket}>
        <div style={{ flex: 1 }}>
            <Typography variant='body1'><strong>{item.name}</strong></Typography>
            <Typography variant='body2'
                color='textSecondary'>{is_checked_in ? moment(item.dropins[0].checkin_at).format('MM/D/YY hh:mm a') : 'Not checked in'} {is_checked_out ? moment(item.dropins[0].checkout_at).format(' - hh:mm a') : ''}</Typography>
        </div>
        <LoadingButton variant='contained'
            color='primary'
            loading={is_loading}
            disabled={state === AttendanceState.off}
            className={state === AttendanceState.checkin && is_checked_in
                || (state === AttendanceState.checkout && is_checked_out) ? classes.success : undefined}
            onClick={onCheckin}>{getAttendanceStateOptionLabel(state, is_checked_in, is_checked_out)}</LoadingButton>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    ticket: {
        background: theme.palette.grey[300],
        padding: theme.spacing(1, 2),
        borderRadius: theme.shape.borderRadius,
        alignItems: 'center',
        display: 'flex',
        margin: theme.spacing(1, 0),
    },
    success: {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    }
}))