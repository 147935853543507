import { createStyles, Link as MaterialLink, makeStyles, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import moment from "moment";
import React from "react";
import { environment } from "../../environment";
import { InvoiceModel } from "../../models/invoice.model";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";


type Props = {
    item: InvoiceModel;
}

export const InvoiceListItem = ({
    item,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.item}>
        <div className={'header'}>
            <div style={{ flex: 1 }}>
                <MaterialLink href={`${environment.frontend_url}/${item.organization.username}`}>
                    <Typography
                        style={{
                            fontWeight: 'bold',
                        }} variant='body1'>{item.organization.name}</Typography></MaterialLink>
                <Typography variant='h6'>
                    {getFormattedCurrency(item.amount, item.currency)}
                </Typography>
                <Typography variant='body2'
                    color='textSecondary'>{moment(item.status_updated_at).format('LLL')}</Typography>
            </div>
            <Link to={`/invoices/${item.id}`}>
                <Typography>View Invoice</Typography>
            </Link>
        </div>
        {item.invoice_items
            .map(invoice_item => <div key={invoice_item.id} className={classes.invoice_item}>
                <div>
                    <Typography gutterBottom variant="body1">
                        {invoice_item.ticket.event.title}: <strong>
                            {invoice_item.ticket.name}
                        </strong>
                    </Typography>
                    <Typography variant='body1'>
                        {invoice_item.quantity} x {getFormattedCurrency(
                            invoice_item.price, item.currency)
                        } each</Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='body1'><strong>{getFormattedCurrency(
                    invoice_item.price * invoice_item.quantity, item.currency)
                }</strong></Typography>
            </div>)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 2, 1, 2),
        '& .header': {
            paddingBottom: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
        },
    },
    invoice_item: {
        display: 'flex',
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        alignItems: 'center',
        '&:last-child': {
            borderBottom: 0,
        }
    }
}))