import { Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React, { useEffect, useMemo } from "react"
import { useNavigate } from "@reach/router";

import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layouts/app-layout"
import { OrganizationReferrerFragment, OrganizationReferrerModel } from "../../models/referrer.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { OrgReferrals } from "../referral/referral-org"
import { ReferrerCreate } from "../referral/referrer-editor/referrer-create"

type Props = {
    org_id: number;
}

export const ReferralJoinPage = ({
    org_id,
}: RouteComponentProps<Props>) => {
    const navigate = useNavigate();
    const {
        entity,
        loading,
        refetch,
    } = useGetByPk<OrganizationReferrerModel>({
        entity_name: 'organization',
        fragment: OrganizationReferrerFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: org_id,
        }]
    });

    const referrer = useMemo(() => entity?.referrers && entity.referrers.length > 0
        ? entity.referrers[0]
        : undefined, [entity]);

    useEffect(() => {
        if (!!referrer) {
            onFinish();
        }
    }, [referrer])

    const onFinish = () => {
        navigate(`/referrals/${org_id}`)
    }

    return <AppLayout>
        <PageHeader
            title={`Join ${entity ? `${entity.name}'s` : ''} Referral Program`}
        />
        {loading ? <LoadingDots /> : null}
        {entity ? <>
            <Typography variant='h6'
                gutterBottom>For each referral you get:</Typography>
            <ul>
                <li><strong>5% of total amount</strong> (pre-tax) in Edropin Gift Card up to $50</li>
                <li>Or, <strong>5% of total amount</strong> (pre-tax) in Amazon or Walmart Gift Card up to $25</li>
            </ul>
            <ReferrerCreate
                org_id={entity.id}
                onFinish={onFinish}
            />
        </> : null}
    </AppLayout>
}
