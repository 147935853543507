
export enum AttendanceState {
    off=0,
    checkin=1,
    checkout=2,
}

export const AttendanceStateOptions = [{
    id: AttendanceState.off,
    label: 'Locked',
    action: 'Is Locked',
},{
    id: AttendanceState.checkin,
    label: 'Check In',
    action: 'Checking In',
},{
    id: AttendanceState.checkout,
    label: 'Check Out',
    action: 'Checking Out',
},]

export const getAttendanceStateOptionLabel = (
    state: AttendanceState,
    is_checked_in?: boolean,
    is_checked_out?: boolean,
) => {
    const option = AttendanceStateOptions.find(o => o.id == state);
    if (!option) {
        return '';
    }
    if (option.id === AttendanceState.checkin && is_checked_in) {
        return 'Checked In';
    }
    if (option.id === AttendanceState.checkout && is_checked_out) {
        return 'Checked Out';
    }
    return option.label;
}

export type WebinarModel = {
    id: number;
    youtube_url: string;
    name: string;
    state: AttendanceState;
}

export const WebinarFragment = `
    id
    youtube_url
    name
    state
`;
