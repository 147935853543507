import { createStyles, DialogContent, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { useList } from "../../apollo/networking";
import { AttendeeDetailFragment, AttendeeDetailModel } from "../../models/attendee-detail.model";
import { AttendeeModel, AttendeeFragment } from "../../models/attendee.model";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { AttendeeEditorDialog } from "../attendee-editor/attendee-editor.dialog";
import { TicketItem } from "../ticket-list/ticket-item";


type Props = {
    onClose: () => void;
    event_id: number;
}

export const EventTicketsDialog = ({
    event_id,
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [edit_at, setEditAt] = React.useState<AttendeeModel | undefined>();
    const {
        items,
        loading,
    } = useList<AttendeeDetailModel>({
        fragment: AttendeeDetailFragment,
        entity_name: 'attendee',
        query_inner: `where: {
            ticket: {
                event_id: {_eq: $event_id}
            }
        }`,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    })
    return <BasicDialog
        id='event-tickets-dialog'
        onClose={onClose}
        open={true}>
        {edit_at ? <AttendeeEditorDialog
            item={edit_at}
            onClose={() => setEditAt(undefined)}
            onFinish={() => setEditAt(undefined)}
        /> : null}
        <BasicDialogTitle title='Tickets' onClose={onClose} />
        <DialogContent>
            <Grid container spacing={2}>
                {items.map(item => <TicketItem
                    key={item.id}
                    onEdit={() => setEditAt(item)}
                    item={item}
                />)}
            </Grid>
            {!loading && items.length === 0 ? <EmptyListComponent
                message='No tickets found.'
            /> : null}
            {loading ? <LoadingDots /> : null}
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({

}))