import { Currency } from "./currency";
import { StripePaymentIntentStatus } from "./stripe-payment-intent-status.enum";

export type ReferrerInvoiceModel = {
    id: number;
    created_at: string;
    updated_at?: string;
    name: string;
    status: StripePaymentIntentStatus;
    status_updated_at?: string;
    org_id: number;
    referrer_id: number;
    amount: number;
    currency: Currency;
}

export const ReferrerInvoiceFragment = `
    id
    created_at
    updated_at
    name
    status
    status_updated_at
    org_id
    amount
    referrer_id
    currency
`;