import React from "react";
import { ReferrerModel } from "../../../models/referrer.model";
import { BasicDialog, BasicDialogTitle } from "../../../ui/dialog";
import { ReferrerCreate } from "./referrer-create";
import { ReferrerEdit } from "./referrer-edit";


type Props = {
    org_id: number;
    action: 'edit' | 'create';
    item?: ReferrerModel;
    onClose: () => void;
    onFinish: (event: ReferrerModel, is_new?: boolean) => void;
}

export const ReferrerEditorDialog = ({
    org_id,
    action,
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-referrer'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title={action === 'create' ? 'Join program' : 'Update details'} />
        {item
            ? <ReferrerEdit
                onFinish={onFinish}
                item={item} />
            : <ReferrerCreate
                org_id={org_id}
                onFinish={onFinish}
            />}
    </BasicDialog>
}
