import { Box, Collapse, createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { AttendeeModel } from "../../models/attendee.model";
import pluralize from 'pluralize'
import QRCode from "react-qr-code";


type Props = {
    attendees: AttendeeModel[];
}

export const InvoiceItemTickets = ({
    attendees,
}: Props) => {
    const classes = useStyles({});
    const [show, setShow] = React.useState(false);
    return <>
        <Collapse in={show}>
            <Box pt={2} pb={2}>
                <Grid container spacing={2}>
                    {attendees.map(at => <Grid
                        item key={at.id}
                        xs={12}
                        sm={6}
                        md={4}>
                        <div className={classes.ticket}>
                            <div className='code'>
                                <QRCode value={at.id.toString()}
                                    size={128} />
                            </div>
                            <Typography>{at.name}</Typography>
                            <Typography variant='body2'
                                color='textSecondary'>{at.email}</Typography>
                            <Typography variant='body2'
                                color='textSecondary'>AGD ID: {at.agd_id}</Typography>
                        </div>
                    </Grid>)}
                </Grid>
            </Box>
        </Collapse>
        {attendees.length > 0 ? <Typography
            className={classes.clickable}
            onClick={() => setShow(t => !t)}
            variant='body2'>{show ? 'Hide' : 'Show'} {attendees.length} {pluralize('ticket', attendees.length)}</Typography> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    clickable: {
        padding: theme.spacing(.5, 0),
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    ticket: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        '& .code': {
            width: 'fit-content',
            margin: '0 auto',
        }
    }
}))