import { gql, useMutation } from "@apollo/client";
import { Box, DialogContent, Typography } from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { parseServerError } from "../../../app-dialog/parse-server-error";
import { ReferrerFragment, ReferrerModel } from "../../../models/referrer.model";
import { BasicDialogActions } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { ReferrerForm } from "./referrer-form";
import { ReferrerInitialValues, ReferrerValidationSchema, validateReferreValues } from "./referrer.validation";

const MUTATION = gql`
    mutation insert($object: referrer_insert_input!) {
        insert_referrer_one(object: $object) {
            ${ReferrerFragment}
        }
    }
`;

type Props = {
    org_id: number;
    onFinish: (product: ReferrerModel, is_new?: boolean) => void;
}

export const ReferrerCreate = ({
    org_id,
    onFinish,
}: Props) => {
    const [insert, status] = useMutation(MUTATION)
    const app_dialog = useAppDialog();

    const onSubmit = async (values: any, helpers: FormikHelpers<any>) => {
        try {
            const { data } = await insert({
                variables: {
                    object: {
                        ...validateReferreValues(values),
                        org_id,
                    }
                }
            });
            const referrer = data?.insert_referrer_one;
            if (!referrer) {
                return;
            }
            app_dialog.showSnackbar('Joined the program!')
            onFinish(referrer, true);
        } catch (e: any) {
            const { message } = parseServerError(e);
            if (typeof message === 'string' && message.startsWith('Uniqueness')) {
                helpers.setErrors({
                    code: `${values.code} is already taken, please try another code`
                })
                return;
            }
            app_dialog.showError(e);
        }
    }
    return <Formik initialValues={ReferrerInitialValues()}
        validationSchema={ReferrerValidationSchema}
        onSubmit={onSubmit}>{(form_props) => <Form noValidate>
            <Typography gutterBottom>We are excited to have you join the program.
                    Please provide a few important details to setup your referral account.</Typography>
            <ReferrerForm {...form_props} />
            <Typography variant='body2'
                color='textSecondary'>By joining the referral program, you agree and have read all <a href=''>Referral program terms and conditions</a>.</Typography>
            <Box textAlign='right' py={2}>
                <LoadingButton loading={status.loading}
                    color='primary'
                    type='submit'
                    size='large'
                    variant='contained'>
                    Join Program
                </LoadingButton>
            </Box>
        </Form>}
    </Formik>
}
