import { AttendeeFragment, AttendeeModel } from "./attendee.model";

export type AttendeeCertificateModel = {
    id: number;
    secret: string;
    updated_at: string;
    pace_submission_id?: string;
    attendee: AttendeeModel;
    certificate: {
        id: number;
        title: string;
        speaker_name: string;
        units: number;
        subject_code: string;
        location: string;
        course_date: string;
        educational_method: string;
    }
};


export const AttendeeCertificateFragment = `
    id
    secret
    updated_at
    pace_submission_id
    attendee {
        ${AttendeeFragment}
    }
    certificate {
        id
        title
        speaker_name
        units
        subject_code
        location
        course_date
        educational_method
    }
`;
