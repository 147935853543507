import { createStyles, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import Linkify from 'react-linkify';
import { LinkifyTruncateText } from "../../ui/linkify-truncate-text";


type Props = {
    event_notifications: {
        id: number;
        message: string;
        created_at: string;
    }[]
}

export const EventNotifications = ({
    event_notifications,
}: Props) => {
    const classes = useStyles({});

    if (event_notifications.length === 0) {
        return null
    }
    
    return <div className={classes.section}>
        <Typography variant='overline'>Notifications</Typography>
        {(event_notifications || []).map(not => <div
            key={not.id} className={classes.notification}>
            <LinkifyTruncateText
                text={not.message}
                component={Linkify}
            />
            <Typography variant='body2' color='textSecondary'>{moment(not.created_at).fromNow()}</Typography>
        </div>)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    section: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    notification: {
        padding: theme.spacing(1, 0),
    },
}))