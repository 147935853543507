import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useList } from "../../apollo/networking"
import { AppLayout } from "../../layouts/app-layout"
import { EventAttendeeFragment, EventAttendeeModel } from "../../models/event-attendee.model"
import { EmptyListComponent } from "../../ui/empty-list.component"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { EventAttendeeItem } from "../dashboard/event-attendee-item"
import { EventCertificatesDialog } from "../dashboard/event-certificates-dialog"
import { EventTicketsDialog } from "../dashboard/event-tickets-dialog"

type Props = {
    event_id: number;
}

export const EventPage = ({
    event_id,
}: RouteComponentProps<Props>) => {
    const [view_tickets, setViewTickets] = React.useState<number | null>(null)
    const [view_certs, setViewCerts] = React.useState<number | null>(null)
    const {
        items,
        error,
        loading,
    } = useList<EventAttendeeModel>({
        entity_name: 'event_by_email',
        fragment: EventAttendeeFragment,
        query_inner: `
            where: {
                event_id: {_eq: $event_id}
            }
        `,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    })
    return <AppLayout>
        <PageHeader
            title='Event Dashboard'
            subtitle={items.length > 0
                ? items[0].event.title : 'Event'}
        />
        {view_tickets ? <EventTicketsDialog
            onClose={() => setViewTickets(null)}
            event_id={view_tickets}
        /> : null}
        {view_certs ? <EventCertificatesDialog
            onClose={() => setViewCerts(null)}
            event_id={view_certs}
        /> : null}
        {items.map(item => <EventAttendeeItem
            key={item.event_id}
            onViewTickets={() => setViewTickets(item.event_id)}
            onViewCerts={() => setViewCerts(item.event_id)}
            item={item}
        />)}
        {loading ? <LoadingDots /> : null}
        {!loading && items.length === 0 ? <EmptyListComponent
            message='No upcoming events found.'
        /> : null}
    </AppLayout>
}
