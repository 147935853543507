import { Button, createStyles, DialogContent, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	EmailIcon, EmailShareButton, FacebookIcon,
	FacebookShareButton, RedditIcon,
	RedditShareButton, TwitterIcon, TwitterShareButton
} from 'react-share';
import { useAppDialog } from '../../app-dialog';
import { environment } from '../../environment';
import { BasicDialog, BasicDialogTitle } from '../../ui/dialog';


type Props = {
	name: string;
	referral_code: string;
	url?: string;
	event_id: number;
	icon_size?: number;
	onClose: () => void;
}

const ICON_SIZE = 60;

export const EventShare = ({
	event_id,
	referral_code,
	url,
	name,
	icon_size = ICON_SIZE,
	onClose,
}: Props) => {
	const app_dialog = useAppDialog();
	const classes = useStyles({});
	const event_url = url ? `${url}-${event_id}` : event_id;
	const _url = `${environment.frontend_url}/e/${event_url}?r=${referral_code}`;

	const onShareWindowClose = (share: string) => {
	}

	return <BasicDialog
		id='video-shared'
		onClose={onClose}
		max_width='sm'
		open={true}>
		<BasicDialogTitle title='Share' onClose={onClose} />
		<DialogContent>
			<div className={classes.shareBar}>
				<div className='item'>
					<FacebookShareButton
						quote={`Watch: ${name}`}
						url={_url}
						onShareWindowClose={() => onShareWindowClose('facebook')}
					>
						<FacebookIcon size={icon_size} round={true} />
					</FacebookShareButton>
				</div>
				<div className='item'>
					<TwitterShareButton
						url={_url}
						onShareWindowClose={() => onShareWindowClose('twitter')}
						title={name}
						hashtags={['eDropin']}>
						<TwitterIcon size={icon_size} round={true} />
					</TwitterShareButton>
				</div>
				<div className='item'>
					<EmailShareButton
						url={_url}
						onShareWindowClose={() => onShareWindowClose('email')}
						subject={name}
						body={`Hey watch this video: ${name} on edropin`}
					>
						<EmailIcon size={icon_size} round={true} />
					</EmailShareButton>
				</div>
				<div className='item'>
					<RedditShareButton
						url={_url}
						onShareWindowClose={() => onShareWindowClose('reddit')}
						title={`Watch ${name}`}
					>
						<RedditIcon size={icon_size} round={true} />
					</RedditShareButton>
				</div>
			</div>

			<TextField
				fullWidth
				margin='dense'
				value={_url}
				InputProps={{
					endAdornment: <InputAdornment position='end'>
						<CopyToClipboard
							text={_url}
							onCopy={() => {
								app_dialog.showSnackbar('Link copied')
								onShareWindowClose('link')
							}}>
							<Button size='small'>Copy</Button>
						</CopyToClipboard>
					</InputAdornment>
				}}
			/>
		</DialogContent>
	</BasicDialog>
}

const useStyles = makeStyles((theme) => createStyles({
	shareBar: {
		display: 'flex',
		justifyContent: 'center',
		'& .item': {
			margin: theme.spacing(1),
		}
	},
}));