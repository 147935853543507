export const DEV_CONFIG = {
  production: false,
  ws_url: 'wss://graphql-cs.herokuapp.com/v1/graphql',
  http_url: 'https://graphql-cs.herokuapp.com/v1/graphql',
  image_base_url: 'https://d1niz8ad8nu5h5.cloudfront.net',
  image_bucket: 'edropin-images-1',
  // ws_url: 'wss://certifysimple-graphql.herokuapp.com/v1/graphql',
  // http_url: 'https://certifysimple-graphql.herokuapp.com/v1/graphql',
  default_page_size: 30,
  frontend_url: 'http://localhost:3001',
  checkout_url: 'http://localhost:3002',
  stripe_key: 'pk_test_Mw3E6nnsYFL58hOd7ZZtL65e',
  firebaseConfig: {
    apiKey: "AIzaSyDwx4klV719KaxtNW5-_kWBrdLiF_K3tus",
    authDomain: "certify-simple.firebaseapp.com",
    projectId: "certify-simple",
    storageBucket: "certify-simple.appspot.com",
    messagingSenderId: "1016111139978",
    appId: "1:1016111139978:web:d0995ecf71c7e63be33173",
    measurementId: "G-ZHX91R4W3C"
  },
  recaptcha_key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};