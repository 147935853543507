import { CssBaseline, Link, ThemeProvider } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Router } from "@reach/router";
import * as qs from 'query-string';
import React from 'react';
import { ProvideApollo } from './apollo';
import { ProvideAppDialog } from './app-dialog/use-app-dialog';
import firebase from './firebase';
import CertificateListPage from './pages/certificate-list';
import DashboardPage from './pages/dashboard';
import DiplomaListPage from './pages/diploma-list';
import EventPage from './pages/event';
import InvoicePage from './pages/invoice';
import InvoiceListPage from './pages/invoice-list';
import ReferralsPage from './pages/referral';
import ReferralJoinPage from './pages/referral-join';
import ReferralListPage from './pages/referral-list';
import ReferralOrgPage from './pages/referral-org';
import TicketPage from './pages/ticket';
import TicketListPage from './pages/ticket-list';
import { theme } from './theme';
import { authErrors } from './utils/firebase-auth-errors';
import WebinarPage from './webinar';

function App() {
  const params = qs.parse(window.location.search);
  const token = params?.token;
  const [login_error, setLoginError] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!token || typeof token !== 'string') {
      return;
    }
    firebase.auth().signInWithCustomToken(token as string)
      .catch(e => {
        const code = e.code.split('/');
        if (firebase.auth().currentUser) {
          return;
        }
        let message = 'Something went wrong on our end. Please contact help@edropin.com if this continues';
        if (code.length === 2) {
          const error = authErrors[code[1]];
          if (error) {
            message = error;
          }
        }
        setLoginError(message);
      });
  }, [token])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAppDialog>
        {login_error ? <Alert severity='error'>
          <AlertTitle>Login Error</AlertTitle>
          {login_error} If this error continues, please contact support at <Link href='mailto:help@certifysimple.com'>help@certifysimple.com</Link>
        </Alert> : null}
        <ProvideApollo>
          <Router>
            <DashboardPage path='/home' />
            <InvoiceListPage path='/invoices' />
            <CertificateListPage path='/certificates' />
            <DiplomaListPage path='/diplomas' />
            <TicketListPage path='/tickets' />
            <EventPage path='/e/:event_id' />
            <TicketPage path='/tickets/:ticket_id' />
            <InvoicePage path='/invoices/:invoice_id' />
            <ReferralsPage path='/referrals' />
            <ReferralOrgPage path='/referrals/:org_id' />
            <ReferralJoinPage path='/referrals/:org_id/join' />
            <ReferralListPage path='/referrals/:org_id/list' />
            <WebinarPage path='/e/:event_id/w/:attendance_id' />
            <DashboardPage default />
          </Router>
        </ProvideApollo>
      </ProvideAppDialog>
    </ThemeProvider>
  );
}

export default App;
