import { Box, Button, createStyles, makeStyles, MenuItem, Typography } from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import * as yup from 'yup';
import { useAppDialog } from "../app-dialog";
import { WebinarAttendeeModel } from "../models/webinar-attendee.model";
import { LoadingButton } from "../ui/loading-button";

type Props = {
    attendees: WebinarAttendeeModel[];
    posting?: boolean;
    onPost: (props: {
        attendee_id: number;
        message: string;
    }) => Promise<boolean>;
    refetch: () => void;
}


const InitialValues = (attendee_id: number) => ({
    attendee_id,
    message: '',
})

const validation_schema = yup.object().shape({
    attendee_id: yup.number().required('Please select a name'),
    message: yup.string().required('Please write a message'),
});

export const WebinarQuestionInput = ({
    attendees,
    posting,
    onPost,
    refetch,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();

    const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
        try {
            if (await onPost({
                attendee_id: values.attendee_id,
                message: values.message,
            })) {
                refetch();
                app_dialog.showSnackbar('Message posted');
                actions.resetForm();
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    return <Formik
        initialValues={InitialValues(attendees[0].id)}
        onSubmit={onSubmit}
        validationSchema={validation_schema}>{({ errors }) => <Form noValidate>
            <Field
                component={TextField}
                name='attendee_id'
                label='Select name'
                margin='normal'
                style={{
                    minWidth: 200,
                }}
                select
            >
                {attendees.map(at => <MenuItem
                    key={at.id}
                    value={at.id}
                >{at.name}</MenuItem>)}
            </Field>
            <Field
                component={TextField}
                name='message'
                label='Ask a question or post a comment'
                fullWidth
                margin='normal'
                multiline
            />
            <Box textAlign='right'>
                <LoadingButton
                    loading={posting}
                    size='large'
                    type='submit'
                    variant='contained'
                    endIcon={<Send />}
                    color='primary'>
                    Post
                </LoadingButton>
            </Box>
        </Form>}</Formik>
}

const useStyles = makeStyles(theme => createStyles({

}))