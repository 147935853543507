import { Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useList } from "../../apollo/networking"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { AppLayout } from "../../layouts/app-layout"
import { OrganizationFragment, OrganizationModel } from "../../models/organization.model"
import { ReferrerInvoiceFragment, ReferrerInvoiceModel } from "../../models/referrer-invoice.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"

type Props = {
    org_id: number;
}

export const ReferralListPage = ({
    org_id,
}: RouteComponentProps<Props>) => {
    const {
        entity,
        loading,
        refetch,
    } = useGetByPk<OrganizationModel>({
        entity_name: 'organization',
        pk_columns: [{
            name: 'id',
            value: org_id,
            type: 'bigint',
        }],
        fragment: OrganizationFragment,
    });
    const {
        items,
        loading: loading_referrals,
    } = useList<ReferrerInvoiceModel>({
        entity_name: 'referrer_invoice',
        fragment: ReferrerInvoiceFragment,
        clause_outer: `$org_id: bigint!`,
        query_inner: `where: {
            org_id: {_eq: $org_id}
        }`,
        variables: {
            org_id,
        }
    });

    return <AppLayout>
        <PageHeader
            title='Referral Awards'
            subtitle='Track your referrals and rewards'
        />
        {loading ? <LoadingDots /> : null}
        {entity ? <>
            {items.length === 0 ? <Typography variant='body1'>No referrals made yet...start referring to earn rewards</Typography> : null}
        </> : null}

    </AppLayout>
}
