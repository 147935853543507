import moment from "moment";
import React, { useMemo, useState } from "react";
import { useList } from "../../apollo/networking";
import { Currency } from "../../models/currency";
import { EventFragment, EventModel } from "../../models/event.model";
import { LoadingDots } from "../../ui/loading-dots";
import { EventListItem } from "./event-list-item";
import { EventShare } from "./event-share";

type Props = {
    org_id: number;
    currency: Currency;
    referral_code: string;
}

export const ReferrableEvents = ({
    org_id,
    currency,
    referral_code,
}: Props) => {
    const timestamp = useMemo(() => moment().startOf('hour').toISOString(), []);
    const [show_share, setShowShare] = useState<EventModel | undefined>();
    const { 
        items,
        loading,
    } = useList<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        query_inner: `where: {
            _and: [
                {org_id: {_eq: $org_id}},
                {tickets: {
                    offer_referral_discount: {_eq: true}}
                },
                {ends_at: {_gte: $timestamp}},
            ]
        }, order_by: {starts_at: asc}`,
        clause_outer: `$org_id: bigint!, $timestamp: timestamptz!`,
        variables: {
            org_id,
            timestamp,
        }
    });

    return <>
        {show_share ? <EventShare
            name={show_share.title}
            url={show_share.url}
            event_id={show_share.id}
            referral_code={referral_code}
            onClose={() => setShowShare(undefined)}
        /> : null}
        {loading ? <LoadingDots /> : null}
        {items.map(item => <div key={item.id}>
            <EventListItem 
                item={item}
                currency={currency}
                referral_code={referral_code}
                onShare={() => setShowShare(item)}
            />
        </div>)}
    </>
}