import { createStyles, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useList } from "../../apollo/networking";
import { AppLayout } from "../../layouts/app-layout";
import { InvoiceFragment, InvoiceModel } from "../../models/invoice.model";
import { StripePaymentIntentStatus } from "../../models/stripe-payment-intent-status.enum";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { InvoiceListItem } from "./invoice-list-item";


type Props = {

}

export const InvoiceListPage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const {
        items,
        error,
        loading,
    } = useList<InvoiceModel>({
        entity_name: 'invoice',
        fragment: InvoiceFragment,
        query_inner: `where: {
            status: {_eq: $status}
        }, order_by: {status_updated_at: desc}`,
        clause_outer: `$status: String!`,
        variables: {
            status: StripePaymentIntentStatus.Succeeded
        }
    })
    return <AppLayout>
        {items.map(item => <InvoiceListItem
            key={item.id}
            item={item}
        />)}
        {loading ? <LoadingDots /> : null}
        {!loading && items.length === 0 ? <EmptyListComponent 
            message='No invoices found.'
        /> : null}
    </AppLayout>
}

const useStyles = makeStyles(theme => createStyles({

}))