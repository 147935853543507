import { Link } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { useAppDialog } from '../../app-dialog';
import { environment } from '../../environment';
import { LoginForm } from './login.form';

const sendEmail = async (values: {
    email: string;
    captcha: string;
    redirect?: string;
}) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var graphql = JSON.stringify({
        query: "mutation login_email($object: login_email_input!) {\n    login_email(object: $object) {\n        success\n    }\n}",
        variables: { "object": values }
    })
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
    };

    const response = await fetch(environment.http_url, requestOptions);
    if (response.status === 200) {
        return await response.json();
    }
}

const Validation = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email address is required'),
    captcha: yup.string().required('Captcha is required'),
})

export const HomePage = () => {
    const classes = useStyles();
    const captcha = React.useRef<ReCAPTCHA>(null);
    const app_dialog = useAppDialog();
    const [loading, setLoading] = React.useState(false);
    const [is_sent, setIsSent] = React.useState(false);

    const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
        try {
            setLoading(true);
            const { data, errors } = await sendEmail({
                ...values,
                redirect: window.location.pathname,
            });
            if (data?.login_email?.success) {
                actions.resetForm();
                setIsSent(true);
                app_dialog.showSnackbar('Login link sent, check your email.');
                return;
            }
            app_dialog.showError({ graphQLErrors: errors })
        } catch (e) {
            app_dialog.showError(e);
        } finally {
            if (captcha.current) {
                captcha.current.reset();
            }
            setLoading(false);
        }
    }

    return (<div className={classes.content}>
        <Container maxWidth='sm' className='centered'>
            <Typography align='center' gutterBottom variant='h1'><strong>Certify</strong>Simple</Typography>
            {is_sent ? <Alert severity='success'>
                <AlertTitle>Login link has been sent.</AlertTitle>
                Check your inbox (and your spam folder) for an email from CertifySimple. If you can't find the email, you can contact support at <Link href='mailto:help@certifysimple.com'>help@certifysimple.com</Link> for a one-time sign in link.
            </Alert> : null}
            <Formik initialValues={{
                email: '',
                captcha: '',
            }}
                validationSchema={Validation}
                onSubmit={onSubmit}>{f => <LoginForm {...f}
                    loading={loading}
                    captcha={captcha} />}</Formik>
        </Container>
    </div>
    );
}


const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(8),
        '& .centered': {
            textAlign: 'center',
        }
    }
}));