import { Box, Button, createStyles, Grid, lighten, makeStyles, Typography } from "@material-ui/core";
import clsx from 'clsx';
import moment from "moment-timezone";
import React from "react";
import { environment } from "../../environment";
import { AttendeeCertificateModel } from "../../models/attendee-certificate.model";

type Props = {
    item: AttendeeCertificateModel;
    onEdit: () => void;
}

export const CertificateItem = ({
    item,
    onEdit,
}: Props) => {
    const classes = useStyles({});

    return <Grid
        item key={item.id}
        xs={12}
        sm={6}>
        <div className={clsx(classes.ticket, {
            [classes.submitted]: !!item.pace_submission_id
        })}>
            <Box textAlign='right'>
                <Button
                    size='small'
                    variant='contained'
                    style={{
                        marginRight: 8,
                    }} onClick={onEdit}>Edit</Button>
                <Button
                    href={`${environment.frontend_url}/certificates/${item.secret}`}
                    target='_blank'
                    size='small'
                    variant='contained'
                    color='primary'>View Certificate</Button>
            </Box>
            <Typography variant='body2'>Title</Typography>
            <Typography variant='h6' gutterBottom>{item.certificate.title}</Typography>
            <Typography variant='body2'>Units</Typography>
            <Typography variant='h6' gutterBottom>{item.certificate.units}</Typography>
            <Typography variant='body2'>Assigned to</Typography>
            <Typography variant='h6' gutterBottom>{item.attendee.name}</Typography>
            {item.attendee.agd_id ? <>
                <Typography variant='body2'>AGD Id</Typography>
                <Typography variant='h6' gutterBottom>{item.attendee.agd_id}</Typography>
            </> : <Button
                size='small'
                color='primary'
                onClick={onEdit}
                variant='contained'>+ Add AGD ID</Button>}
            <hr />
            
            <Typography
                gutterBottom variant='body2'>Assigned: <strong>{moment(item.updated_at).format('LLL')}</strong></Typography>
            {item.pace_submission_id ? <Typography variant='body2'>Submitted to PACE: <strong>{item.pace_submission_id}</strong></Typography> :
                <div>
                        <Button
                            href={`${environment.frontend_url}/certificates/${item.secret}?submit=1`}
                            target='_blank'
                            disabled={!item.attendee.agd_id}
                            color='primary' variant='contained'>Submit to PACE</Button>
                    {!item.attendee.agd_id ? <Typography color='secondary' variant='body2'>Add your AGD ID to submit this certificate to PACE</Typography> : null}
                </div>}
            {/* <Link target='_blank' href={`${environment.frontend_url}/e/${item.ticket.event.id}`}>
                <Typography variant='body1'>{item.ticket.event.title}</Typography>
            </Link>
            <Typography variant='body2'
                color='textSecondary'><strong>Ticket:</strong> {item.ticket.name}</Typography> */}
        </div>
    </Grid>
}

const useStyles = makeStyles(theme => createStyles({
    ticket: {
        background: theme.palette.grey[300],
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        '& .code': {
            width: 'fit-content',
            margin: '0 auto',
        }
    },
    submitted: {
        background: lighten(theme.palette.success.light, .7),
    }
}))