import { Button, createStyles, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { convertFromRaw } from 'draft-js';
import React from "react";
import { environment } from "../../environment";
import { EventModel } from "../../models/event.model";
import { getTruncatedText } from "../../ui/get-truncated-text";
import { ImageSize, LazyImage } from "../../ui/image";
import { getTimeRangeTimezone } from "../../utils/time-range";
import ShareIcon from '@material-ui/icons/Send'
import { Currency } from "../../models/currency";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";


type Props = {
    item: EventModel;
    currency: Currency;
    referral_code: string;
    onShare: () => void;
}

export const EventListItem = ({
    item,
    currency,
    referral_code,
    onShare,
}: Props) => {
    const classes = useStyles({});
    const description = React.useMemo(() =>
        item.content ? convertFromRaw(item.content).getPlainText() : '', [item.content]);

    const feature_image = React.useMemo(() => {
        return item.feature_images && item.feature_images.length > 0
            ? item.feature_images[0] : undefined;
    }, [item.feature_images]);

    return <div className={classes.item}>
        {feature_image ? <div className={classes.image}>
            <LazyImage
                image_key={feature_image}
                image_opt={{
                    size: ImageSize.xlarge,
                    aspect_ratio: 'none',
                }}
            />
        </div> : null}
        <div className={'header'}>
            <Link href={`/e/${item.url ? `${item.url}-${item.id}` : item.id}`}>
                <Typography
                    style={{
                        fontWeight: 'bold',
                    }} variant='body1'>{item.title}</Typography>
            </Link>
            <Typography gutterBottom variant='body2' color='textSecondary'>
                {getTimeRangeTimezone(item.starts_at, item.ends_at, item.timezone)}
            </Typography>
            {description ? <Typography gutterBottom variant='body2' color='textSecondary'>
                {getTruncatedText(description, '', 240)}
            </Typography> : null}

            <div className={classes.tickets}>
                <Typography variant='body1'>By referring, your friends save money! Here are the discounts they will earn on the following tickets:</Typography>
                {item.tickets
                    .filter(t => t.offer_referral_discount)
                    .map(ticket => <div key={ticket.id} className='item'>
                        <Typography variant='body1'><strong>{ticket.name}</strong></Typography>
                        <Typography className='price'><span className='strike'>{getFormattedCurrency(ticket.price, currency)}</span> {getFormattedCurrency(ticket.referral_discount_price || 0, currency)}</Typography>
                    </div>)}
            </div>

            <div className='buttons'>
                <Button
                    onClick={onShare}
                    startIcon={<ShareIcon />}
                    variant='contained' color='primary'>
                    Make a referral
                </Button>

                <div style={{ flex: 1 }} />
                <Tooltip title='Learn more'>
                    <Button target='_blank' href={`${environment.frontend_url}/e/${item.id}?r=${referral_code}`} variant='outlined'>
                        Learn More
                        </Button>
                </Tooltip>
            </div>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(2),
        '& .header': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(2, 2, 1, 2),
            '& a': {
                color: 'inherit',
            },
            '& .buttons': {
                margin: theme.spacing(0, -.5),
                display: 'flex',
                alignItems: 'center',
                '& a': {
                    margin: theme.spacing(0, .5),
                },
                '& button': {
                    margin: theme.spacing(0, .5),
                }
            }
        },
        '& .stat': {
            padding: theme.spacing(3, 0),
            textAlign: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& .number': {
                fontSize: '3.5em',
                lineHeight: '1em',
            },
            '& p': {
                fontSize: '.9em',
            }
        },
    },
    image: {
        width: '100%',
        height: 0,
        paddingTop: '50%',
        backgroundColor: 'black',
        position: 'relative',
        '& img': {
            position: 'absolute',
            left: 0,
            top: 0,
            objectFit: 'contain',
            width: '100%',
            height: '100%',
        }
    },
    tickets: {
        padding: theme.spacing(2, 0),
        '& .item': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 0),
            '&:last-child': {
                borderBottom: 0,
            },
            '& .price': {
                fontWeight: 'bold',
                '& .strike': {
                    textDecoration: 'line-through'
                }
            }
        },
    }
}))
