import { Box, Button, createStyles, lighten, makeStyles, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";
import { environment } from "../../environment";
import { AttendeeDiplomaModel } from "../../models/attendee-diploma.model";

type Props = {
    item: AttendeeDiplomaModel;
    onEdit: () => void;
}

export const DiplomaItem = ({
    item,
    onEdit,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.ticket}>
        <Box textAlign='right'>
            <Button
                size='small'
                variant='contained'
                style={{
                    marginRight: 8,
                }} onClick={onEdit}>Edit Info</Button>
            <Button
                href={`${environment.frontend_url}/diplomas/${item.secret}`}
                target='_blank'
                size='small'
                variant='contained'
                color='primary'>View Diploma</Button>
        </Box>
        <Typography variant='body2'>Diploma</Typography>
        <Typography variant='h6' gutterBottom>{item.diploma.title}</Typography>
        <Typography variant='body2'>Event</Typography>
        <Typography variant='h6' gutterBottom>{item.diploma.event.title}</Typography>
        <Typography variant='body2'>Assigned to</Typography>
        <Typography variant='h6' gutterBottom>{item.attendee.name}</Typography>

        <Typography
            gutterBottom variant='body2'>Assigned: <strong>{moment(item.updated_at).format('LLL')}</strong></Typography>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    ticket: {
        background: theme.palette.grey[200],
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        borderRadius: theme.shape.borderRadius,
        '& .code': {
            width: 'fit-content',
            margin: '0 auto',
        }
    },
    submitted: {
        background: lighten(theme.palette.success.light, .7),
    }
}))