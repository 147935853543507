import { Button, createStyles, makeStyles } from "@material-ui/core"
import GiftIcon from '@material-ui/icons/CardGiftcard'
import React, { useMemo, useState } from "react"
import { OrganizationReferrerModel, ReferrerModel } from "../../models/referrer.model"
import { UnstyledLink } from "../../ui/unstyled-link"
import { JoinProgramItem } from "./join-program-item"
import { ReferrerEditorDialog } from "./referrer-editor/referrer-editor.dialog"
import { ReferrerSummary } from "./referrer-summary"
import SendIcon from '@material-ui/icons/Send';

type Props = {
    org: OrganizationReferrerModel;
    refetch: () => void;
}

export const OrgReferrals = ({ org, refetch }: Props) => {
    const classes = useStyles({});
    const [editor, setEditor] = useState<{
        item?: ReferrerModel;
        action: 'edit' | 'create'
    } | undefined>();

    const referrer = useMemo(() => {
        return org.referrers && org.referrers.length > 0 ? org.referrers[0] : undefined;
    }, [org])

    return <>
        {editor ? <ReferrerEditorDialog
            org_id={org.id}
            item={editor.item}
            action={editor.action}
            onClose={() => setEditor(undefined)}
            onFinish={() => {
                setEditor(undefined);
                refetch();
            }}
        /> : null}
        {referrer ? <div className={classes.item}>
            <ReferrerSummary
                referrer_name={referrer.name}
                referrer_code={referrer.code}
                org_name={org.name}
                org_logo_image_key={org.logo_image_key}
                currency={org.currency}
                total_referrals={referrer.summary?.total_referrals || 0}
                referrer_created_at={referrer.created_at}
                total_referral_amount={
                    (referrer.summary?.total_referral_amount || 0) - (referrer.summary?.total_refund_amount || 0)}
            />
            <Button
                variant='contained'
                color='primary'
                startIcon={<GiftIcon />}
                style={{
                    marginRight: 8,
                }}
                size='large'>See My Rewards</Button>
            <UnstyledLink to={`/referrals/${org.id}`}>
                <Button startIcon={<SendIcon />} variant='contained' color='secondary' size='large'>Refer a friend</Button>
            </UnstyledLink>
        </div> : <JoinProgramItem
            org={org} />}
    </>
}


const useStyles = makeStyles((theme) => createStyles({
    item: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2, 0),
    },
}))
