import { Button, createStyles, makeStyles, Typography } from "@material-ui/core"
import GiftIcon from '@material-ui/icons/CardGiftcard'
import React from "react"
import { OrganizationModel } from "../../models/organization.model"
import { ImageSize, LazyImage } from "../../ui/image"
import { UnstyledLink } from "../../ui/unstyled-link"
import amazonGiftCard from './1.jpg'
import walmartGiftCard from './2.jpg'
import edropinGiftCard from './3.jpg'

type Props = {
    org: OrganizationModel;
}

export const JoinProgramItem = ({
    org,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.item}>
        <LazyImage
            image_key={org.logo_image_key}
            image_opt={{
                size: ImageSize.medium,
            }}
            className={classes.image}
        />
        <Typography variant='h5'><strong>{org.name}</strong></Typography>
        <Typography gutterBottom>Get rewarded with <strong>discounts & gift cards</strong> for sharing {org.name} events and courses with friends and colleagues.</Typography>
        <div className={classes.giftcards}>
            <div className='item'>
                <img src={edropinGiftCard} alt='Edropin gift card' />
            </div>
            <div className='item'>
                <img src={amazonGiftCard} alt='Amazon gift card' />
            </div>
            <div className='item'>
                <img src={walmartGiftCard} alt='Walmart gift card' />
            </div>
        </div>
        <UnstyledLink to={`/referrals/${org.id}/join`}>
            <Button
                variant='contained'
                size='large'
                startIcon={<GiftIcon />}
                color='secondary'>Join Referral Program</Button>
        </UnstyledLink>
    </div>
}

const useStyles = makeStyles((theme) => createStyles({
    item: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2, 0),
    },
    image: {
        width: 80,
        height: 80,
        borderRadius: theme.shape.borderRadius,
    },
    giftcards: {
        display: 'flex',
        margin: theme.spacing(0, -.5),
        '& .item': {
            padding: theme.spacing(.5),
            width: '30%',
            '& img': {
                width: '100%',
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[3],
            }
        },
    }
}))