import React from "react";
import { AttendeeModel } from "../../models/attendee.model";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import { AttendeeEdit } from "./attendee-edit";


type Props = {
    item: AttendeeModel;
    onClose: () => void;
    onFinish: (attendee: AttendeeModel) => void;
}

export const AttendeeEditorDialog = ({
    onClose,
    onFinish,
    item,
}: Props) => {
    return <BasicDialog
        open={true}
        id='update-attendee'
        onClose={onClose}>
        <BasicDialogTitle onClose={onClose} title='Edit Ticket' />
        <AttendeeEdit
            onFinish={onFinish}
            item={item} />
    </BasicDialog>
}
