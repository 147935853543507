import { gql, useMutation } from "@apollo/client";

const MutationCheckin = gql`
    mutation do_dropin($object: do_dropin_input!) {
        do_dropin_webinar(object: $object) {
            attendee_id
            attendance_id
        }
    }
`;

type Props = {
    attendance_id: number;
}

export const useDropin = ({
    attendance_id,
}: Props) => {
    const [
        dropin,
    ] = useMutation(MutationCheckin)
    const doDropin = async (attendee_id: number) => {
        return dropin({
            variables: {
                object: {
                    attendance_id,
                    attendee_id,
                },
            },
        });
    }
    return doDropin;
}