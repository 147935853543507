import { Box, Button, createStyles, makeStyles, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import ReactPlayer from 'react-player'
import { useList } from "../apollo/networking"
import { useSubscribeByPk } from "../apollo/networking/use-subscribe-by-pk"
import { environment } from "../environment"
import { AppLayout } from "../layouts/app-layout"
import { WebinarAttendeeFragment, WebinarAttendeeModel } from "../models/webinar-attendee.model"
import { WebinarFragment, WebinarModel } from "../models/webinar.model"
import { LoadingDots } from "../ui/loading-dots"
import { PageHeader } from "../ui/page-header"
import { WebinarQuestions } from "./webinar-questions"
import { WebinarSignin } from "./webinar-signin"

type Props = {
    attendance_id: number;
    event_id: number;
}

export const WebinarPage = ({
    attendance_id,
    event_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const {
        entity: webinar,
        loading,
    } = useSubscribeByPk<WebinarModel>({
        entity_name: 'attendance',
        fragment: WebinarFragment,
        pk_columns: [{
            value: attendance_id,
            type: 'bigint',
            name: 'id',
        }],
    })
    const {
        items,
        loading: loading_attendees,
        refetch,
    } = useList<WebinarAttendeeModel>({
        fragment: WebinarAttendeeFragment,
        entity_name: 'attendee',
        query_inner: `where: {
            ticket: {
                event_id: {_eq: $event_id}
            }
        }`,
        clause_outer: `$event_id: bigint!, $attendance_id: bigint!`,
        variables: {
            event_id,
            attendance_id,
        }
    })

    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        <PageHeader
            title={webinar?.name || 'Webinar'}
        />
        {items.length > 0 ? <>
            <Alert severity='info'>
                <AlertTitle>Don't forget to Check In <span role='img'>👋</span> and <strong>Unmute <span role='img'>🔈</span></strong></AlertTitle>
            Make sure you check in to receive your certificate after the webinar
        </Alert>
            {webinar ? <WebinarSignin
                attendees={items}
                webinar={webinar}
                refetchAttendees={refetch}
            /> : null}
        </> : <Alert
            severity='error'>
            <AlertTitle>Not Registered for Webinar</AlertTitle>
            <Typography gutterBottom>Please check that you are logged in with the same email you used to register for the webinar. Also, if you haven't registered for this webinar,
                    you may be able to register now. <span role='img'>👇</span>
            </Typography>
            <Button
                variant='contained'
                color='secondary' href={`${environment.frontend_url}/e/${event_id}`}>Register now</Button>
        </Alert>}
        {webinar ? <>
            {!webinar?.youtube_url ? <Box py={4} textAlign='center'>
                <Typography>Video not available. Try refreshing the page...</Typography>
            </Box> : <div className={classes.player}>
                <ReactPlayer
                    url={webinar?.youtube_url}
                    width='100%'
                    height='100%'
                    controls={true}
                    playing
                    muted
                />
            </div>}
            {items.length > 0 ?
                <WebinarQuestions
                    attendees={items}
                    attendance_id={attendance_id as number}
                /> : null}
        </> : null}
    </AppLayout>
}


const useStyles = makeStyles(theme => createStyles({
    player: {
        width: '100%',
        height: 0,
        paddingTop: '56.25%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'grey',
        '& iframe': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        }
    }
}))