import { RouteComponentProps } from "@reach/router";
import { useList } from "../../apollo/networking";
import { AppLayout } from "../../layouts/app-layout";
import { OrganizationReferrerFragment, OrganizationReferrerModel } from "../../models/referrer.model";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { OrgReferrals } from "./referral-org";

type Props = {};


export const ReferralsPage = ({ }: RouteComponentProps<Props>) => {
    const {
        items,
        loading,
        refetch,
    } = useList<OrganizationReferrerModel>({
        entity_name: 'organization',
        fragment: OrganizationReferrerFragment,
        query_inner: `where: {is_referral_program_enabled: {_eq: true}}, order_by: {created_at: desc}`
    });

    return <AppLayout>
        <PageHeader
            title='Join Referral Programs'
            subtitle='Manage your organization referrals'
        />
        {loading ? <LoadingDots /> : null}
        {items.map(item => <div key={item.id}>
            <OrgReferrals
                org={item}
                refetch={refetch}
            />
        </div>)}
    </AppLayout>
}
