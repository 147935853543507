export type WebinarPostModel = {
    id: number;
    created_at: string;
    message: string;
    is_public?: boolean;
    replied_at?: string; 
}

export const WebinarPostFragment = `
    id
    created_at
    message
    is_public
    replied_at
`;

export type WebinarPostAttendeeModel = {
    id: number;
    created_at: string;
    name: string;
    message: string;
    is_public?: boolean;
    replied_at?: string; 
}

export const WebinarPostAttendeeFragment = `
    id
    created_at
    name
    message
    is_public
    replied_at
`;
