import { OrganizationFragment, OrganizationModel } from "./organization.model";

export type ReferrerModel = {
    id: number;
    created_at: string;
    name: string;
    phone: string;
    code: string;
    org_id: number;
    summary?: {
        total_referrals?: number;
        total_referral_amount?: number;
        total_refund_amount?: number;
    }
}

export const ReferrerFragment = `
    id
    created_at
    name
    phone
    code
    org_id
    summary {
        total_referrals
        total_referral_amount
        total_refund_amount
    }
`;

export interface OrganizationReferrerModel extends OrganizationModel {
    referrers?: ReferrerModel[];
}

export const OrganizationReferrerFragment = `
    ${OrganizationFragment}
    referrers {
        ${ReferrerFragment}
    }
`;


export interface ReferrerOrganizationModel extends ReferrerModel {
    organization: OrganizationModel;
}

export const ReferrerOrganizationFragment = `
    ${ReferrerFragment}
    organization {
        ${OrganizationFragment}
    }
`;