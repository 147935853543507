import { Box, createStyles, makeStyles, Typography } from "@material-ui/core"
import { ErrorMessage, Field, Form, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React from "react"
import ReCAPTCHA from 'react-google-recaptcha';
import { environment } from "../../environment";
import { LoadingButton } from "../../ui/loading-button";


interface Props extends FormikProps<any> {
    captcha: React.MutableRefObject<any>;
    loading?: boolean;
}

export const LoginForm = ({
    setFieldValue,
    captcha,
    loading,
}: Props) => {
    const classes = useStyles({});

    function onCaptchaChange(value: string | null) {
        setFieldValue('captcha', value || '');
    }

    return <Form noValidate>
        <Field
            component={TextField}
            name='email'
            type='email'
            label='Email address'
            fullWidth
            margin='normal'
        />
        <ReCAPTCHA
            sitekey={environment.recaptcha_key}
            onChange={onCaptchaChange}
            ref={captcha}
        />
        <Box textAlign='left'>
            <ErrorMessage name='captcha' render={msg => <Typography gutterBottom align='left' variant='body2' color='secondary'>{msg}</Typography>} />
        </Box>
        <LoadingButton
            color='secondary'
            fullWidth
            variant='contained'
            type='submit'
            loading={loading}
            size='large'>
            Send my login link
        </LoadingButton>
    </Form>
}

const useStyles = makeStyles(theme => createStyles({

}))