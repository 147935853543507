import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useList } from "../../apollo/networking";
import { AppLayout } from "../../layouts/app-layout";
import { AttendeeCertificateFragment, AttendeeCertificateModel } from "../../models/attendee-certificate.model";
import { AttendeeModel } from "../../models/attendee.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { AttendeeEditorDialog } from "../attendee-editor/attendee-editor.dialog";
import { CertificateItem } from "./certificate-item";


type Props = {

}

export const CertificateListPage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const [edit_attendee, setEditAttendee] = React.useState<AttendeeModel|undefined>();
    const {
        items,
        error,
        loading,
    } = useList<AttendeeCertificateModel>({
        entity_name: 'attendee_certificate',
        fragment: AttendeeCertificateFragment,
        query_inner: `order_by: {created_at: desc}`,
        // clause_outer: `$!`,
        // variables: {

        // }
    })

    return <AppLayout>
        <PageHeader
            no_back
            title='Certificates'
        />
        {edit_attendee ? <AttendeeEditorDialog 
            item={edit_attendee}
            onClose={() => setEditAttendee(undefined)}
            onFinish={() => setEditAttendee(undefined)}
        /> : null}
        <Grid container spacing={2}>
            {items.map(item => <CertificateItem
                key={item.id}
                item={item}
                onEdit={() => setEditAttendee(item.attendee)}
            />)}
        </Grid>
        {!loading && items.length === 0 ? <EmptyListComponent 
            message='No certificates found.'
        /> : null}
        {loading ? <LoadingDots /> : null}
    </AppLayout>
}

const useStyles = makeStyles(theme => createStyles({

}))