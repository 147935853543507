import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { useAppDialog } from "../app-dialog";
import { WebinarAttendeeModel } from "../models/webinar-attendee.model";
import { WebinarModel } from "../models/webinar.model";
import { EmptyListComponent } from "../ui/empty-list.component";
import { useDropin } from "./use-dropin";
import { WebinarTicketItem } from "./webinar-ticket-item";



type Props = {
    attendees: WebinarAttendeeModel[];
    webinar: WebinarModel;
    refetchAttendees: () => void;
}

export const WebinarSignin = ({
    attendees,
    webinar,
    refetchAttendees,
}: Props) => {
    const app_dialog = useAppDialog();
    const [dropin_loading, setLoading] = React.useState<{
        [id: number]: boolean
    }>({});
    const doDropin = useDropin({
        attendance_id: webinar.id,
    })

    const _doDropin = async (item_id: number) => {
        setLoading(l => ({
            ...l,
            [item_id]: true,
        }));
        try {
            if (await doDropin(item_id)) {
                app_dialog.showSnackbar('Dropin completed')
                refetchAttendees();
            }
        } catch (e) {
            app_dialog.showError(e)
        } finally {
            setLoading(l => ({
                ...l,
                [item_id]: false,
            }));
        }
    }

    return <>
        {attendees.map(item => <WebinarTicketItem
            key={item.id}
            is_loading={dropin_loading[item.id]}
            state={webinar.state}
            item={item}
            onCheckin={() => _doDropin(item.id)}
        />)}
        {attendees.length === 0 ? <EmptyListComponent
            message='No tickets found.'
        /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))