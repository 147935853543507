import { createStyles, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useList } from "../../apollo/networking";
import { AppLayout } from "../../layouts/app-layout";
import { AttendeeDiplomaFragment, AttendeeDiplomaModel } from "../../models/attendee-diploma.model";
import { AttendeeModel } from "../../models/attendee.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { AttendeeEditorDialog } from "../attendee-editor/attendee-editor.dialog";
import { DiplomaItem } from "./diploma-item";


type Props = {

}

export const DiplomaListPage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const [edit_attendee, setEditAttendee] = React.useState<AttendeeModel | undefined>();
    const {
        items,
        error,
        loading,
    } = useList<AttendeeDiplomaModel>({
        entity_name: 'attendee_diploma',
        fragment: AttendeeDiplomaFragment,
        query_inner: `order_by: {created_at: desc}`,
        // clause_outer: `$!`,
        // variables: {

        // }
    })

    return <AppLayout>
        <PageHeader
            no_back
            title='Diplomas'
        />
        {edit_attendee ? <AttendeeEditorDialog
            item={edit_attendee}
            onClose={() => setEditAttendee(undefined)}
            onFinish={() => setEditAttendee(undefined)}
        /> : null}
        {items.map(item => <DiplomaItem
            key={item.id}
            item={item}
            onEdit={() => setEditAttendee(item.attendee)}
        />)}
        {!loading && items.length === 0 ? <EmptyListComponent
            message='No diplomas found.'
        /> : null}
        {loading ? <LoadingDots /> : null}
    </AppLayout>
}

const useStyles = makeStyles(theme => createStyles({

}))