export type EventModel = {
    id: number;
    title: string;
    org_id: number;
    starts_at: string;
    ends_at: string;
    timezone?: string;
    url?: string;
    content?: any;
    feature_images?: string[];
    tickets: {
        id: number;
        price: number;
        name: string;
        offer_referral_discount?: boolean;
        referral_discount_price?: number;
    }[];
}

export const EventFragment = `
    id
    title
    org_id
    url
    starts_at
    ends_at
    timezone
    feature_images
    content
    tickets(order_by:{referral_discount_price: asc}) {
        id
        name
        price
        offer_referral_discount
        referral_discount_price
    }
`;
