import { Button, Hidden, IconButton, Tooltip, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useLocation } from '@reach/router';
import React, { useState } from 'react';
import { useUser } from '../apollo/use-user';
import { UnstyledLink } from '../ui/unstyled-link';


type Props = {
  onBack?: () => void;
}

const paths: any[] = [
  { link: '/home', label: 'Dashboard' },
  { link: '/invoices', label: 'Invoices' },
  { link: '/tickets', label: 'Tickets' },
  { link: '/certificates', label: 'Certificates' },
  { link: '/diplomas', label: 'Diplomas' },
  { link: '/referrals', label: 'Referrals' },
]

export const AppHeader: React.FC<Props> = ({ onBack }) => {
  const classes = useStyles();
  const [is_menu_open, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const {
    email,
    signout
  } = useUser();

  const path_name = location.pathname;
  const _paths = React.useMemo(() => {
    return paths.map(p => ({
      ...p,
      active: path_name === '/' ? p.link === '/home' : path_name.startsWith(p.link),
    }))
  }, [path_name])

  const toggleMenu = () => {
    setIsMenuOpen(o => !o);
  }

  return (
    <div className={classes.root}>
      <AppBar color='default' position='fixed'>
        <Toolbar>
          <UnstyledLink to={'/'} className={classes.title}>
            <Typography variant='h6'><strong>Certify</strong>Simple</Typography>
          </UnstyledLink>
          <div style={{ flex: 1 }} />
          <Hidden smDown>
            <div className={classes.links}>
              {_paths.map(p => <Link
                key={p.link}
                to={p.link} className={p.active ? 'active' : ''}>{p.label}</Link>)}
            </div>
            <Tooltip title={email}>
              <Button onClick={signout}>Sign Out</Button>
            </Tooltip>
          </Hidden>
          <Hidden mdUp>
            <div className={is_menu_open ? classes.fullNav : classes.hiddenNav}>
              <IconButton onClick={toggleMenu} className='close'>
                <CloseIcon />
              </IconButton>
              {_paths.map(p => <Link
                key={p.link}
                to={p.link} className={p.active ? 'active' : ''}>{p.label}</Link>)}
              <Tooltip title={email} className='signout'>
                <Button size='large' color='secondary' onClick={signout}>Sign Out</Button>
              </Tooltip>
            </div>

            <Tooltip title={email}>
              <IconButton onClick={toggleMenu}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hiddenNav: {
    display: 'none',
  },
  fullNav: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'white',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      display: 'block',
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1, 0),
      color: 'inherit',
      fontSize: theme.typography.body1.fontSize,
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      }
    },
    '& .close': {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    '& .signout': {
      marginTop: theme.spacing(4),
    }
  },
  title: {
    marginTop: 2,
    '& .logo': {
      height: 30,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
    },
    '& .icon': {
      height: 30,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
    }
  },
  links: {
    '& a': {
      display: 'inline-block',
      padding: theme.spacing(1),
      color: 'inherit',
      textDecoration: 'none',
      '&.active': {
        fontWeight: 'bold',
      }
    }
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  }
}));