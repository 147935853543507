import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import CertIcon from '@material-ui/icons/CloudDownload';
import TicketIcon from '@material-ui/icons/ConfirmationNumber';
import React from "react";
import { Link } from '@reach/router'
import { EventAttendeeModel } from "../../models/event-attendee.model";
import { getTimeRangeTimezone } from "../../utils/time-range";
import { EventNotifications } from "./event-notifications";
import { EventResources } from "./event-resources";

type Props = {
    item: EventAttendeeModel;
    onViewTickets: () => void;
    onViewCerts: () => void;
}

export const EventAttendeeItem = ({
    item,
    onViewTickets,
    onViewCerts,
}: Props) => {
    const classes = useStyles();
    const { event, attendee_ids } = item;

    return <div className={classes.item}>
        <Typography variant='h6'>
            <Link to={`/e/${event.id}`}>{event.title}</Link>
        </Typography>
        <Typography variant='body1'
            gutterBottom>{getTimeRangeTimezone(
                event.starts_at,
                event.ends_at,
                event.timezone)}</Typography>
        <div className={classes.buttons}>
            <Button
                variant='contained'
                color='primary'
                onClick={onViewTickets}
                startIcon={<TicketIcon />}
            >View {attendee_ids.length} Tickets</Button>
            <Button
                variant='contained'
                color='secondary'
                onClick={onViewCerts}
                startIcon={<CertIcon />}
            >Download Certificates</Button>
        </div>
        <EventNotifications event_notifications={event.event_notifications || []} />
        <EventResources event_resources={event.event_resources || []} />
    </div>
}


const useStyles = makeStyles(theme => createStyles({
    item: {
        margin: theme.spacing(2, 0),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
    },
    section: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    buttons: {
        padding: theme.spacing(1, 0),
        '& button': {
            marginRight: theme.spacing(1),
        }
    },
    notification: {
        padding: theme.spacing(1, 0),
    }
}))