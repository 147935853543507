import { createStyles, Link, makeStyles, Typography } from "@material-ui/core"
import moment from "moment"
import React from "react"
import { environment } from "../../environment"
import { Currency } from "../../models/currency"
import { ImageSize, LazyImage } from "../../ui/image"
import { getFormattedCurrency } from "../../utils/get-formatted-currency"

type Props = {
    referrer_name: string;
    referrer_code: string;
    org_name: string;
    org_logo_image_key?: string;
    referrer_created_at: string;
    total_referrals: number;
    total_referral_amount: number;
    currency: Currency;
}

export const ReferrerSummary = ({
    referrer_name,
    referrer_code,
    org_name,
    org_logo_image_key,
    referrer_created_at,
    total_referral_amount,
    total_referrals,
    currency,
}: Props) => {
    const classes = useStyles({})
    return <>
        <Typography variant='h6'>{referrer_name} <Link target='_blank' href={`${environment.frontend_url}?r=${referrer_code}`}>{referrer_code}</Link></Typography>
        <Typography variant='body2' color='textSecondary'>Joined {moment(referrer_created_at).format('MMM D, yyyy')}</Typography>
        <div className={classes.name}>
            <LazyImage
                image_key={org_logo_image_key}
                image_opt={{
                    size: ImageSize.small,
                }}
                className={classes.image}
            />
            <Typography variant='body1'><strong>{org_name}</strong></Typography>
        </div>
        <div className={classes.stat}>
            <div className='padded'>
                <Typography variant='h6'
                    className='number'>{total_referrals}</Typography>
                <Typography
                    color='textSecondary'
                    variant='body2'>REFERRALS</Typography>
            </div>
            <div className='padded'>
                <Typography variant='h6'
                    className='number'>{getFormattedCurrency(
                        total_referral_amount,
                        currency)}</Typography>
                <Typography
                    color='textSecondary'
                    variant='body2'>REFERRAL AMOUNT</Typography>
            </div>
        </div>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    image: {
        width: 30,
        height: 30,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(.5),
    },
    name: {
        padding: theme.spacing(.5, 0),
        display: 'flex',
        alignItems: 'center',
    },
    stat: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 0),
        textAlign: 'center',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        justifyContent: 'space-around',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        '& .padded': {
            padding: theme.spacing(1, 2),
        },
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
        },
        '& .number': {
            fontSize: '3.5em',
            lineHeight: '1em',
        },
        '& p': {
            fontSize: '.9em',
        }
    },
}));