import { AttendeeFragment, AttendeeModel } from "./attendee.model";
import { DropinFragment, DropinModel } from './dropin.model';


export interface WebinarAttendeeModel extends AttendeeModel {
    dropins: DropinModel[];
};


export const WebinarAttendeeFragment = `
    ${AttendeeFragment}
    dropins(where: {
        attendance_id: {_eq: $attendance_id}
    }) {
      ${DropinFragment}     
    }
`;
