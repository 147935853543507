import { Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import moment from "moment";
import React from "react";
import { useList } from "../../apollo/networking";
import { AppLayout } from "../../layouts/app-layout";
import { EventAttendeeFragment, EventAttendeeModel } from "../../models/event-attendee.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { EventAttendeeItem } from "./event-attendee-item";
import { EventCertificatesDialog } from "./event-certificates-dialog";
import { EventTicketsDialog } from "./event-tickets-dialog";


type Props = {

}

export const DashboardPage = (props: RouteComponentProps<Props>) => {
    const timestamp = React.useMemo(() => moment().subtract(1, 'd').toISOString(), [])
    const [view_tickets, setViewTickets] = React.useState<number | null>(null)
    const [view_certs, setViewCerts] = React.useState<number | null>(null)
    const {
        items,
        error,
        loading,
    } = useList<EventAttendeeModel>({
        entity_name: 'event_by_email',
        fragment: EventAttendeeFragment,
        query_inner: `where: {
            event: {
                ends_at: {_gt: $timestamp}
            }
        },order_by: {event: {starts_at: asc}}`,
        clause_outer: `$timestamp: timestamptz`,
        variables: {
            timestamp,
        }
    });
    const {
        items: past_events,
        loading: loading_past_events,
    } = useList<EventAttendeeModel>({
        entity_name: 'event_by_email',
        fragment: EventAttendeeFragment,
        query_inner: `where: {
            event: {
                ends_at: {_lte: $timestamp}
            }
        },order_by: {event: {starts_at: desc}}`,
        clause_outer: `$timestamp: timestamptz`,
        variables: {
            timestamp,
        }
    });

    return <AppLayout>
        <PageHeader
            no_back
            title={'Welcome to CertifySimple'}
            subtitle='Your upcoming events'
        />
        {view_tickets ? <EventTicketsDialog
            onClose={() => setViewTickets(null)}
            event_id={view_tickets}
        /> : null}
        {view_certs ? <EventCertificatesDialog
            onClose={() => setViewCerts(null)}
            event_id={view_certs}
        /> : null}
        {items.map(item => <EventAttendeeItem
            key={item.event_id}
            onViewTickets={() => setViewTickets(item.event_id)}
            onViewCerts={() => setViewCerts(item.event_id)}
            item={item}
        />)}
        {!loading && items.length === 0 ? <EmptyListComponent
            message='No upcoming events found.'
        /> : null}
        <Typography variant='overline'>Past Events</Typography>
        {past_events.map(item => <EventAttendeeItem
            key={item.event_id}
            onViewTickets={() => setViewTickets(item.event_id)}
            onViewCerts={() => setViewCerts(item.event_id)}
            item={item}
        />)}
        {!loading_past_events && past_events.length === 0 ? <EmptyListComponent
            message='No past events found.'
        /> : null}
        {loading_past_events ? <LoadingDots /> : null}

    </AppLayout>
}
