import { Box, Button, createStyles, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import QRCode from "react-qr-code";
import { environment } from "../../environment";
import { AttendeeDetailModel } from "../../models/attendee-detail.model";
import { getTimeRangeTimezone } from "../../utils/time-range";


type Props = {
    item: AttendeeDetailModel;
    onEdit: () => void;
}

export const TicketItem = ({
    item,
    onEdit,
}: Props) => {
    const classes = useStyles({});

    return <Grid
        item key={item.id}
        xs={12}
        sm={6}>
        <div className={classes.ticket}>
            <Box textAlign='right'>
                <Button size='small' onClick={onEdit}>
                    Edit
                </Button>
            </Box>
            <div className='code'>
                <QRCode value={item.id.toString()}
                    size={128} />
            </div>
            <Typography variant='body2'>Name</Typography>
            <Typography variant='h6' gutterBottom>{item.name}</Typography>
            <Typography variant='body2'>Email</Typography>
            <Typography variant='h6' gutterBottom>{item.email}</Typography>
            {item.agd_id ? <>
                <Typography variant='body2'>AGD ID</Typography>
                <Typography variant='h6' gutterBottom>{item.agd_id}</Typography>
            </> : <Button
                size='small'
                color='primary'
                onClick={onEdit}
                style={{
                    marginBottom: 8,
                }}
                variant='contained'>+ Add AGD ID</Button>}
            <Typography variant='body2'>Event Date</Typography>
            <Typography variant='h6'>
                {getTimeRangeTimezone(item.ticket.event.starts_at, item.ticket.event.ends_at, item.ticket.event.timezone)}
            </Typography>
            <hr />
            <Link target='_blank' href={`${environment.frontend_url}/e/${item.ticket.event.id}`}>
                <Typography variant='body1'>{item.ticket.event.title}</Typography>
            </Link>
            <Typography variant='body2'
                color='textSecondary'><strong>Ticket:</strong> {item.ticket.name}</Typography>
        </div>
    </Grid>
}

const useStyles = makeStyles(theme => createStyles({
    ticket: {
        background: theme.palette.grey[300],
        height: '100%',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        '& .code': {
            width: 'fit-content',
            margin: '0 auto',
        }
    }
}))