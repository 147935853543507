import React from "react";
import firebase from '../firebase';

export type UserContextModel = {
    email: string;
    signout: () => void;
}

export const UserContext = React.createContext<UserContextModel>({} as any);

type Props = {
    email: string;
}

export const ProvideUser: React.FC<Props> = ({email, children}) => {
   
    const signout = () => {
        firebase.auth().signOut();
    }

    return <UserContext.Provider value={{
        email,
        signout,
    }}>
        {children}
    </UserContext.Provider>
}

export const useUser = (): UserContextModel => {
    return React.useContext(UserContext);
}