import { AttendeeFragment, AttendeeModel } from "./attendee.model";
import { Currency } from "./currency";
import { OrganizationFragment, OrganizationModel } from "./organization.model";
import { StripePaymentIntentStatus } from "./stripe-payment-intent-status.enum";

export type InvoiceModel = {
    id: number;
    cancelled_at?: string;
    expires_at?: string;
    name: string;
    email: string;
    agd_id?: string;
    discount_code?: string;
    organization: OrganizationModel;
    currency: Currency;
    subtotal: number;
    tax_total: number;
    secret: string;
    tax_info?: {
        name: string;
        description?: string;
        tax_percent: number;
    }[];
    discount_total: number;
    discounts?: {
        type: string;
    }[];
    status: StripePaymentIntentStatus;
    status_updated_at?: string;
    amount: number;
    invoice_items: {
        id: number;
        quantity: number;
        schema: any;
        price: number;
        discount?: number;
        amount: number;
        is_ready?: boolean;
        attendees?: AttendeeModel[];
        ticket: {
            id: number;
            name: string;
            price: number;
            event: {
                id: number;
                title: string;
            }
        }
    }[];
    refund_tally?: {
        total_amount?: number;
    }
}

export const InvoiceFragment = `
    id
    cancelled_at
    expires_at
    name
    email
    agd_id
    discount_code
    organization {
        ${OrganizationFragment}
    }
    currency
    subtotal
    tax_total
    tax_info
    discount_total
    discounts
    amount
    status
    secret
    status_updated_at
    invoice_items {
        id
        quantity
        schema
        price
        discount
        amount
        attendees {
            ${AttendeeFragment}
        }
        ticket {
            id
            name
            price
            event {
                id
                title
            }
        }
    }
    refund_tally {
        total_amount
    }
`;
