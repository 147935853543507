import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { WebinarPostFragment } from "../models/webinar-post.model";

const InsertMutation = gql`
    mutation insert_post($object: webinar_post_insert_input!) {
        insert_webinar_post_one(object: $object) {
            ${WebinarPostFragment}
        }
    }
`

export const usePostQuestion = (attendance_id: number) => {
    const [insert, insert_status] = useMutation(InsertMutation);

    const postQuestion = async (props: {
        message: string;
        attendee_id: number;
    }) => {
        const { data } = await insert({
            variables: {
                object: {
                    ...props,
                    attendance_id,
                }
            }
        });
        return true;
    }

    return {
        postQuestion,
        posting: insert_status.loading,
    }
}