import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useList } from "../../apollo/networking";
import { AppLayout } from "../../layouts/app-layout";
import { AttendeeDetailFragment, AttendeeDetailModel } from "../../models/attendee-detail.model";
import { AttendeeFragment, AttendeeModel } from "../../models/attendee.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { AttendeeEditorDialog } from "../attendee-editor/attendee-editor.dialog";
import { TicketItem } from "./ticket-item";


type Props = {

}

export const TicketListPage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const [edit_at, setEditAt] = React.useState<AttendeeModel|undefined>();
    const {
        items,
        error,
        loading,
    } = useList<AttendeeDetailModel>({
        entity_name: 'attendee',
        fragment: AttendeeDetailFragment,
        query_inner: `order_by: {created_at: desc}`,
        // clause_outer: `$!`,
        // variables: {

        // }
    });

    return <AppLayout>
        <PageHeader
            no_back
            title='All Tickets'
        />
        {edit_at ? <AttendeeEditorDialog 
            item={edit_at}
            onClose={() => setEditAt(undefined)}
            onFinish={() => setEditAt(undefined)}
        /> : null}
        <Grid container spacing={2}>
            {items.map(item => <TicketItem
                key={item.id}
                onEdit={() => setEditAt(item)}
                item={item}
            />)}
        </Grid>
        {!loading && items.length === 0 ? <EmptyListComponent 
            message='No tickets found.'
        /> : null}
        {loading ? <LoadingDots /> : null}
    </AppLayout>
}

const useStyles = makeStyles(theme => createStyles({

}))