export type EventAttendeeModel = {
    id: number;
    attendee_ids: number[];
    ticket_ids: number[];
    event_id: number;
    email: string;
    event: {
        id: number;
        title: string;
        starts_at: string;
        ends_at: string;
        timezone: string;
        event_notifications?: {
            id: number;
            message: string;
            created_at: string;
        }[];
        event_resources?: {
            id: number;
            name: string;
            created_at: string;
            icon_type: number;
            link: string;
        }[];
    };
};


export const EventAttendeeFragment = `
    id
    attendee_ids
    ticket_ids
    event_id
    email
    event {
        id
        title
        starts_at
        ends_at
        timezone
        event_notifications(order_by:{ created_at: desc }) {
            id
            message
            created_at
        }
        event_resources(order_by: {created_at: asc}) {
            id
            name
            created_at
            icon_type
            link
        }
    }
`;
