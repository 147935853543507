export type DropinModel = {
    id: number;
    attendance_id: number;
    attendee_id: number;
    checkin_at: string;
    checkout_at: string;
}

export const DropinFragment = `
    id    
    attendance_id
    attendee_id
    checkin_at
    checkout_at
`;