import { Box, Button, createStyles, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React, { useMemo } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import {
    EmailIcon, EmailShareButton, FacebookIcon,
    FacebookShareButton, RedditIcon,
    RedditShareButton, TwitterIcon, TwitterShareButton
} from 'react-share'
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { useUser } from "../../apollo/use-user"
import { useAppDialog } from "../../app-dialog"
import { environment } from "../../environment"
import { AppLayout } from "../../layouts/app-layout"
import { ReferrerOrganizationFragment, ReferrerOrganizationModel } from "../../models/referrer.model"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { ReferrerSummary } from "../referral/referrer-summary"
import { ReferrableEvents } from "./referrerable-events"

const icon_size = 60;

type Props = {
    org_id: number;
}

export const ReferralOrgPage = ({
    org_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const {
        email,
    } = useUser();
    const {
        entity,
        loading,
    } = useGetByPk<ReferrerOrganizationModel>({
        entity_name: 'referrer',
        fragment: ReferrerOrganizationFragment,
        pk_columns: [{
            name: 'org_id',
            type: 'bigint',
            value: org_id,
        }, {
            name: 'email',
            type: 'citext',
            value: email,
        }]
    });

    const referral_link = useMemo(() => !!entity ? `${environment.frontend_url}/${entity.organization.username}?r=${entity.code}` : '', [entity])
    const name = useMemo(() => !!entity ? `Hey, check out ${entity.organization.name}'s CE calendar.` : '', [entity])

    return <AppLayout>
        <PageHeader
            title={'Make a referral'}
            subtitle='Share your event links, earn amazing rewards'
        />
        {loading ? <LoadingDots /> : null}
        {entity
            ? <>
                <ReferrerSummary
                    referrer_name={entity.name}
                    referrer_code={entity.code}
                    org_name={entity.organization.name}
                    org_logo_image_key={entity.organization.logo_image_key}
                    currency={entity.organization.currency}
                    total_referrals={entity.summary?.total_referrals || 0}
                    referrer_created_at={entity.created_at}
                    total_referral_amount={
                        (entity.summary?.total_referral_amount || 0) - (entity.summary?.total_refund_amount || 0)}
                />
                <Box pb={4}>
                    <Typography variant='h6'
                        gutterBottom>What do you earn for each referral:</Typography>
                    <ul>
                        <li><strong>5% of total amount</strong> (pre-tax) in Edropin Gift Card up to $50</li>
                        <li>Or, <strong>5% of total amount</strong> (pre-tax) in Amazon or Walmart Gift Card up to $25</li>
                    </ul>
                </Box>

                <Typography variant='h4'>
                    <strong>How to make a referral:</strong>
                </Typography>
                <Box pb={2}>
                    <Typography variant='body1'>Simply share this link to {entity.organization.name}'s calendar. When they checkout they will get a discount and you will get credit for the referral.</Typography>
                    <TextField
                        fullWidth
                        margin='dense'
                        value={referral_link}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <CopyToClipboard
                                    text={referral_link}
                                    onCopy={() => {
                                        app_dialog.showSnackbar('Link copied')
                                    }}>
                                    <Button size='small'>Copy</Button>
                                </CopyToClipboard>
                            </InputAdornment>
                        }}
                    />
                    <div className={classes.shareBar}>
                        <div className='item'>
                            <FacebookShareButton
                                quote={`Watch: ${name}`}
                                url={referral_link}
                            >
                                <FacebookIcon size={icon_size} round={true} />
                            </FacebookShareButton>
                        </div>
                        <div className='item'>
                            <TwitterShareButton
                                url={referral_link}
                                title={name}
                                hashtags={['eDropin']}>
                                <TwitterIcon size={icon_size} round={true} />
                            </TwitterShareButton>
                        </div>
                        <div className='item'>
                            <EmailShareButton
                                url={referral_link}
                                subject={name}
                                body={`Hey watch this video: ${name} on edropin`}
                            >
                                <EmailIcon size={icon_size} round={true} />
                            </EmailShareButton>
                        </div>
                        <div className='item'>
                            <RedditShareButton
                                url={referral_link}
                                title={`Watch ${name}`}
                            >
                                <RedditIcon size={icon_size} round={true} />
                            </RedditShareButton>
                        </div>
                    </div>
                </Box>
                <Typography variant='h6' gutterBottom>Or, share an event directly <span role='img'>👇</span>. You can see how much they can save because of your referral!</Typography>
                <ReferrableEvents
                    org_id={entity.org_id}
                    currency={entity.organization.currency}
                    referral_code={entity.code}
                />
            </> : null}

    </AppLayout>
}

const useStyles = makeStyles(theme => createStyles({
    shareBar: {
		display: 'flex',
		justifyContent: 'center',
		'& .item': {
			margin: theme.spacing(1),
		}
	},
}))