export type AttendeeDetailModel = {
    id: number;
    created_at: string;
    updated_at: string;
    agd_id?: string;
    email: string;
    name: string;
    data?: any;
    ticket: {
        id: number;
        name: string;
        event: {
            id: number;
            title: string;
            starts_at: string;
            ends_at: string;
            timezone: string;
        }
    }
};


export const AttendeeDetailFragment = `
id
created_at
updated_at
name
email
agd_id
data
ticket {
    id
    name
    event {
        id
        title
        starts_at
        ends_at
        timezone
    }
}
`;
