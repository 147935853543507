import * as yup from 'yup';
import { ReferrerModel } from '../../../models/referrer.model';

export const ReferrerInitialValues = (referrer?: ReferrerModel) => ({
    name: referrer?.name || '',
    code: referrer?.code || '',
    phone: referrer?.phone || '',
})

export const ReferrerValidationSchema = yup.object().shape({
    name: yup.string().required('Please provide a ticket name'),
    phone: yup.string().required('Please provide a phone number'),
    code: yup.string().required('Please enter a referral code that works for you')
})

export const validateReferreValues = (object: any) => ({
    ...object,
    code: cleanCode(object.code),
})

export const cleanCode = (url?: string) => {
    if (!url) {
        return '';
    }
    const allowed = 'abcdefghijklmnopqrstuvwxyz1234567890-';
    return url.toLowerCase()
    .replace(/ /g, '-')
    .split('').filter(l => allowed.indexOf(l) > -1).join('');
}